<template>
  <div class="container-box">
    <div class="main-box">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item
          ><router-link to="/">首页</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item
          ><router-link to="/info">资讯速递</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item style="color: #15b7dd;">详情</a-breadcrumb-item>
      </a-breadcrumb>

      <div class="content-box">
        <div class="content-left">
          <div class="header">{{ banner?data.articleTitle:data.title }}</div>
          <div class="brief">
            <div class="time-num">
              <div class="brief-time">发布时间：{{ data.createTime }}</div>
              <div class="brief-num">浏览量：{{ banner?data.pageviews:data.views }}</div>
            </div>
            <div class="brief-source">
              来源：<span class="blue">{{ data.source }}</span>
            </div>
          </div>
          <div class="text" v-html="banner?data.pcContent:data.pcDetails"></div>
          <div class="footer" v-if="!banner">
            <div class="footer-top" v-if="data.upTitle">
              上一篇：<span class="black" @click="toDetail(data.upNewsId)">{{
                data.upTitle
              }}</span>
            </div>
            <div class="footer-top" v-if="data.downTitle">
              下一篇：<span class="black" @click="toDetail(data.downNewsId)">{{
                data.downTitle
              }}</span>
            </div>
          </div>
        </div>

        <div class="content-right">
          <div class="hot-title"></div>
          <div class="title-box">
            <div class="line"></div>
            热门资讯
          </div>
          <div class="list">
            <div
              v-for="(item, index) in hotList"
              :key="index"
              class="hot-list"
            >
              <img
                v-if="item.pcImg"
                class="hot-banner"
                :src="item.pcImg"
                alt=""
                @click="toDetail(item.newsId)"
              />
              <div class="hot-content">
                <div class="hot-title" @click="toDetail(item.newsId)">{{ item.title }}</div>
                <div class="hot-source">
                  <span>来源：{{ item.source }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      data: [],
      hotList: [],
    };
  },
  // 事件处理器
  methods: {
    toDetail(x) {
      this.$router.replace(
        "/infoDetail?newsId=" + this.$AES.encode_data(String(x))
      );
    },

    getDetail(id) {
      let url = '',
          params = {};
      if(this.banner) {
        url = '/hxclass-pc/Article/detail/' + id;
      }else{
        url = '/hxclass-pc/news/pc/details';
        params = {
          newsId: id
        }
      }
      this.$ajax({
        url,
        params
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.data = res.data;
          if(this.banner) {
            this.data.createTime = this.data.publishTime.substring(0, 10);
            this.getVolume(id)
          }else{
            this.data.createTime = this.data.createTime.substring(0, 10);
          }
        }
      });
    },

    // 增加点击量
    getVolume(id) {
      this.$ajax({
        url: "/hxclass-pc/Article/up/" + id,
      }).then((res) => {});
    },

    getHot() {
      this.$ajax({
        url: "/hxclass-pc/news/pc/hot",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.hotList = res.data;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.id = this.$AES.decode_data(this.$route.query.newsId);
    this.banner = this.$route.query.banner;
    this.getDetail(this.id);
    this.getHot();
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$emit("setIndex", false);
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    $route() {
      this.id = this.$AES.decode_data(this.$route.query.newsId);
      this.banner = false
      this.getDetail(this.id);
    },
  },
};
</script>

<style lang="less" scoped>
.container-box {
  .main-box {
    padding-top: 22px;
    padding-bottom: 60px;
    .content-box {
      margin-top: 22px;
      width: 100%;
      overflow: hidden;

      .content-left {
        float: left;
        padding: 0 30px;
        width: 950px;
        background-color: #fff;
        .header {
          padding: 36px 0 31px;
          border-bottom: 1px solid #f2f2f2;
          font-size: 32px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: @text-color;
          line-height: 48px;
          text-align: center;
        }
        .brief {
          display: flex;
          justify-content: space-between;
          margin-top: 19px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
          letter-spacing: 1px;

          .time-num {
            display: flex;
            .brief-time {
              margin-right: 60px;
            }
            .brief-num {
            }
          }

          .brief-source {
            .blue {
              color: @border-color;
            }
          }
        }
        /deep/.text {
          // width: 100%;
          margin-top: 30px;
          img{
            width: 100%;
          }
          strong{
            font-weight: bold;
          }
          em{
            font-style: italic;
          }
        }
        .footer {
          margin: 35px 0;
          .footer-top {
            padding: 5px 0;
            // width: 162px;
            border-bottom: 1px solid #f2f2f2;
            font-size: 18px;
            font-family: PingFangMedium;
            font-weight: 400;
            color: #999999;
            line-height: 27px;
            .black {
              cursor: pointer;
              color: @text-color;
            }
          }
          .footer-top:last-child {
            border-bottom: none;
          }
        }
      }

      .content-right {
        float: right;
        // padding: 36px 30px 30px;
        width: 430px;
        background-color: #fff;

        .title-box {
          margin: 30px 30px 0 30px;
          padding-bottom: 31px;
          border-bottom: 1px solid #f2f2f2;
          font-size: 24px;
          font-family: PingFangMedium;
          font-weight: 500;
          color: #15b7dd;
          line-height: 28px;
          .line {
            display: inline-block;
            margin-right: 8px;
            width: 4px;
            height: 19px;
            background: #15b7dd;
            border-radius: 2px 2px 2px 2px;
            vertical-align: middle;
          }
        }

        .list {
          .hot-list {
            transition: all 0.4s ease;
            display: flex;
            padding: 25px 30px;
            border-bottom: 1px solid #f2f2f2;
            .hot-banner {
              cursor: pointer;
              margin-right: 20px;
              width: 116px;
              min-width: 116px;
              height: 88px;
              box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
              border-radius: 5px 5px 5px 5px;
            }
            .hot-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              // min-height: 88px;
              .hot-title {
                cursor: pointer;
                font-size: 18px;
                font-family: PingFangMedium;
                font-weight: 500;
                color: #333333;
                line-height: 27px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                -webkit-line-clamp: 2;
              }
              .hot-source {
                width: 100%;
                margin-top: 15px;
                span {
                  font-size: 14px;
                  font-family: PingFangMedium;
                  font-weight: 400;
                  color: #666666;
                  line-height: 21px;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  -webkit-line-clamp: 1;
                }
              }
            }
            &:hover {
              transform: translateY(-2px);
              box-shadow: 2px 2px 10px 0px #cecece;
              .hot-content {
                .hot-title {
                  color: #15B7DD;
                }
              }
            }
          }
        }
      }
    }
  }
}


// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .container-box {
    .main-box {
      .content-box {
        .content-left{
          width: 820px;
          .header{
            font-size: 28px;
            padding: 28px 0 24px;
          }
          .brief{
            font-size: 12px;
          }
          .footer .footer-top{
            font-size: 16px;
          }
        }
        .content-right{
          // padding: 30px;
          width: 360px;
          .title-box{
            padding-bottom: 20px;
            font-size: 20px;
            font-weight: bold;
          }
          .list{
            .hot-list{
              padding: 20px 30px;
              .hot-banner {
                width: 110px;
                min-width: 110px;
              }
              .hot-content{
                .hot-title{
                  font-size: 16px;
                }
                .hot-source{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
